<template>
  <div>
    <TopMenu title="title"></TopMenu>
  </div>
</template>

<script>
import TopMenu from "../navigation/TopMenu";
export default {
  name: "Structure",
  components: { TopMenu },
  data() {
    return {
      title: "Главная",
    };
  },
};
</script>

<style scoped></style>
